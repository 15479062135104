<template>
    <div>
        <b-modal :id="$route.name + 'CreateModal'" title="Добавить Тип служебной записки" size="md" hide-footer no-enforce-focus :no-close-on-backdrop="true" @show="clearData">
            <b-row>
                <b-col cols="12">
                    <label for="">Наименование</label>
                    <b-input size="sm" v-model="memoType.name" placeholder="Наименование" />
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <label for="">Тип сз</label>
                    <v-select
                    :reduce="options => options.id"
                    :options="memoSubjectId"
                    label="name"
                    v-model="memoType.memo_subject_id"
                    ></v-select>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" class="d-flex justify-content-between">
                    <b-button
                    :disabled="submitButtonDisabled"
                    class="mt-2 col-md-5"
                    variant="primary"
                    @click="createMemoType">
                        Добавить
                    </b-button>
                    <b-button class="mt-2 col-md-5" variant="secondary" @click="closeModal">
                        Отмена
                    </b-button>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>
<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
export default {
    components: {
        ToastificationContent,
        vSelect,
    },
    props: ['memoSubjectId'],
    data(){
        return {
            memoType: {
                name: '',
                memo_subject_id: null,
                is_active: 1
            },
            submitButtonDisabled: false,
        }
    },
    methods:{
        createMemoType(){
            this.memoType.is_active=1
            this.submitButtonDisabled = true
            this.$http
                .post('memo-types', this.memoType)
                .then(res=> {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Успех!',
                            icon: 'CheckIcon',
                            variant: 'success',
                            text: res.data.message,
                        },
                    })
                    this.$bvModal.hide(this.$route.name + 'CreateModal')
                    this.$emit('refresh')
                })
                .catch(err=> {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Некорректные данные!',
                            icon: 'XIcon',
                            variant: 'danger',
                            text: err.response.data.errors,
                        },
                    })
                })
                .finally(()=>{
                    this.submitButtonDisabled = false
                })
        },
        closeModal(){
            this.$bvModal.hide(this.$route.name + 'CreateModal')
            this.clearData()
        },
        clearData(){
            this.memoType={
                name: '',
                is_active: 1
            }
        }
    }
}
</script>