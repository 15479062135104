<template>
<div>
    <div class="d-flex justify-content-center align-items-center" v-if="showPreloader || $store.state.refresh.fetchingNewData || $store.state.refresh.waiting_filter" style="height: 90vh;">
        <b-spinner variant="primary" label="Text Centered" />
    </div>
    <div v-else>
        <table-memo-type :items="items" @editForm="editForm" @refresh="refresh" />
    </div>
    <modal-memo-type @refresh="refresh" :memoSubjectId="memoSubjectId"/>
    <modal-memo-type-edit :id="id" @refresh="refresh" :memoSubjectId="memoSubjectId"/>
</div>
</template>
<script>
import ModalMemoType from '@/views/component/Modal/ModalMemoType/ModalMemoType.vue'
import ModalMemoTypeEdit from '@/views/component/Modal/ModalMemoType/ModalMemoTypeEdit.vue'
import TableMemoType from '@/views/component/Table/tableMemoType.vue'
export default {
    components: {
        ModalMemoType,
        ModalMemoTypeEdit,
        TableMemoType,
    },
    data(){
        return {
            items: [],
            memoSubjectId: [],
            showPreloader: false,
            id: undefined,
        }
    },
    mounted(){
        this.$store.commit('pageData/setdataCount', null)
        this.refresh()
        this.memoSubject() 
    },
    methods: {
        editForm(id){
            this.id = id
            setTimeout(this.openModal, 0);
        },
        openModal(){
            this.$bvModal.show('memoTypeEdit')
        },
        refresh(){
            this.showPreloader = true
            this.$http
                .get('memo-types')
                .then(res => {
                    this.items = res.data
                    this.$store.commit('pageData/setdataCount', this.items.length)
                    this.showPreloader = false
                    this.$store.commit('REFRESH_DATA', false)
                }).catch(err => {})
        },
        memoSubject() {
            this.$http
                .get('memo-subjects') 
                .then((res) => {
                    this.memoSubjectId = res.data
                })
        }
    },
    computed: {
        fetchingNewData(){
            return this.$store.state.refresh.fetchingNewData
        }
    },
    watch: {
        fetchingNewData(val){
            if (val){
                this.$http
                .get(`${this.$route.name}`)
                .then(res =>{
                    this.items = res.data
                    this.$store.commit('pageData/setdataCount', this.items.length)
                    this.$store.commit('REFRESH_DATA', false)
                })
                }
            }
    }
}
</script>